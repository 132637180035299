var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"all-page"},[_c('hr'),_c('div',{staticClass:"metric-toggle-tabs"},[_c('router-link',{class:{
        active:
          _vm.$route.name === 'PostcodesPage' ||
          _vm.$route.name === 'PostcodesVaccinationsPage',
      },attrs:{"to":{
        name: (_vm.$route.name || '').endsWith('VaccinationsPage')
          ? 'PostcodesVaccinationsPage'
          : 'PostcodesPage',
      }}},[_vm._v(" By postcode ")]),_c('router-link',{class:{
        active:
          _vm.$route.name === 'CouncilsPage' ||
          _vm.$route.name === 'CouncilsVaccinationsPage',
      },attrs:{"to":{
        name: (_vm.$route.name || '').endsWith('VaccinationsPage')
          ? 'CouncilsVaccinationsPage'
          : 'CouncilsPage',
      }}},[_vm._v(" By council ")])],1),(_vm.vaccineMode)?_c('OverallVaccinations'):_vm._e(),_c('h1',{staticClass:"table-title"},[_vm._v(" "+_vm._s(_vm.vaccineMode ? "COVID-19 vaccination rates" : "COVID-19 cases")+" by "+_vm._s(_vm.councilMode ? "council" : "postcode")+" ")]),(_vm.vaccineMode)?_c('div',{staticClass:"table-subtitle"},[_vm._v(" Data up to "),_c('mark',[_vm._v(_vm._s(_vm.vaccineTemporalCoverageString))]),_vm._v(", updated weekly by "+_vm._s(_vm.councilMode ? "Department of Health" : "NSW Health")+". "),_c('div',{staticClass:"table-subtitle-disclaimer"},[_vm._v(" "+_vm._s(_vm.VACCINATIONS_NOTE)+" ")])]):_c('div',{staticClass:"table-subtitle"},[_vm._v(" Data up to "),_c('mark',[_vm._v(_vm._s(_vm.temporalCoverageString))]),_vm._v(", updated each Fri/Sat. Includes both RAT and PCR tests. "),_c('div',{staticClass:"table-subtitle-disclaimer"},[_vm._v(" *Many cases aren’t added by NSW Health within 1 day, so today’s numbers are less than final values. ")])]),(!_vm.vaccineMode)?_c('label',{staticClass:"per-pop-toggle"},[_c('button',{class:{ active: !_vm.perPopMode },on:{"click":function($event){_vm.$store.state.listPagePerPopMode = false}}},[_vm._v(" Cases ")]),_c('button',{class:{ active: _vm.perPopMode },on:{"click":function($event){_vm.$store.state.listPagePerPopMode = true}}},[_vm._v(" Cases per "+_vm._s(_vm.PER_POPULATION)+" people ")])]):_vm._e(),_c('div',{staticClass:"table"},[_c('table',[_c('thead',[_c('tr',[_c('th',{staticClass:"primary-col"},[(_vm.councilMode)?_c('a',{attrs:{"href":"#","title":"Sort by Council/LGA"},on:{"click":function($event){$event.preventDefault();return _vm.$store.commit(
                  _vm.vaccineMode
                    ? 'setListPageVaccinationsSort'
                    : 'setListPageCasesSort',
                  'col1Sort'
                )}}},[_vm._v(" Council/LGA "),(_vm.sort === 'col1Sort')?_c('div',[_vm._v("▼")]):_vm._e()]):_c('a',{attrs:{"href":"#","title":"Sort by Postcode"},on:{"click":function($event){$event.preventDefault();return _vm.$store.commit(
                  _vm.vaccineMode
                    ? 'setListPageVaccinationsSort'
                    : 'setListPageCasesSort',
                  'col1Sort'
                )}}},[_vm._v(" Postcode "),(_vm.sort === 'col1Sort')?_c('div',[_vm._v("▼")]):_vm._e()])]),(!_vm.vaccineMode)?_c('th',{staticClass:"num-col"},[_c('a',{attrs:{"href":"#","title":"Sort by cases today"},on:{"click":function($event){$event.preventDefault();return _vm.$store.commit('setListPageCasesSort', 'newCasesToday')}}},[_vm._m(0),(_vm.sort === 'newCasesToday')?_c('div',[_vm._v("▼")]):_vm._e()])]):_vm._e(),(!_vm.vaccineMode)?_c('th',{staticClass:"num-col"},[_c('a',{attrs:{"href":"#","title":"Sort by cases this week"},on:{"click":function($event){$event.preventDefault();return _vm.$store.commit('setListPageCasesSort', 'newCasesThisWeek')}}},[_vm._v(" This week "),(_vm.sort === 'newCasesThisWeek')?_c('div',[_vm._v("▼")]):_vm._e()])]):_vm._e(),(!_vm.vaccineMode)?_c('th',{staticClass:"num-col"},[_c('a',{attrs:{"href":"#","title":"Sort by total cases"},on:{"click":function($event){$event.preventDefault();return _vm.$store.commit('setListPageCasesSort', 'totalCases')}}},[_c('span',[_vm._v(" Total cases ")]),(_vm.sort === 'totalCases')?_c('div',[_vm._v("▼")]):_vm._e()])]):_vm._e(),(_vm.vaccineMode)?_c('th',{staticClass:"num-col"},[_c('a',{attrs:{"href":"#","title":"Sort by cases this week"},on:{"click":function($event){$event.preventDefault();return _vm.$store.commit('setListPageVaccinationsSort', 'dose1')}}},[_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("1st dose")]),(_vm.sort === 'dose1')?_c('div',[_vm._v("▼")]):_vm._e()])]):_vm._e(),(_vm.vaccineMode)?_c('th',{staticClass:"num-col"},[_c('a',{attrs:{"href":"#","title":"Sort by cases this week"},on:{"click":function($event){$event.preventDefault();return _vm.$store.commit('setListPageVaccinationsSort', 'dose2')}}},[_c('span',{staticStyle:{"white-space":"nowrap"}},[_vm._v("2nd dose")]),(_vm.sort === 'dose2')?_c('div',[_vm._v("▼")]):_vm._e()])]):_vm._e()])]),_c('tbody',_vm._l((_vm.truncate
            ? _vm.postcodeRowsSortedTruncated
            : _vm.postcodeRowsSorted),function(value){return _c('tr',{key:value.postcodeNumber,attrs:{"role":"button"},on:{"click":function($event){return _vm.$router.push(
              _vm.councilMode
                ? {
                    name: 'CouncilPage',
                    params: { councilSlug: value.councilSlug },
                  }
                : {
                    name: 'PostcodePage',
                    params: { postcode: value.postcodeNumber },
                  }
            )}}},[(_vm.councilMode)?_c('td',{staticClass:"council-name"},[_c('router-link',{attrs:{"to":{
                name: 'CouncilPage',
                params: { councilSlug: value.councilSlug },
              }}},[_vm._v(_vm._s(value.councilName))])],1):_c('td',[_c('router-link',{attrs:{"to":{
                name: 'PostcodePage',
                params: { postcode: value.postcodeNumber },
              }}},[_vm._v(_vm._s(value.postcodeNumber))]),_vm._v("  "),_c('div',[_vm._v(_vm._s(value.suburbs))])],1),(!_vm.vaccineMode)?_c('td',[_vm._v(" "+_vm._s(_vm.formatCasesValue(value.newCasesToday))+" ")]):_vm._e(),(!_vm.vaccineMode)?_c('td',[_vm._v(" "+_vm._s(_vm.formatCasesValue(value.newCasesThisWeek))+" ")]):_vm._e(),(!_vm.vaccineMode)?_c('td',[_vm._v(" "+_vm._s(_vm.formatCasesValue(value.totalCases))+" ")]):_vm._e(),(_vm.vaccineMode)?_c('td',[_vm._v(" "+_vm._s(value.dose1 || "-")+" ")]):_vm._e(),(_vm.vaccineMode)?_c('td',[_vm._v(" "+_vm._s(value.dose2 || "-")+" ")]):_vm._e()])}),0)]),(_vm.truncate && _vm.rowCount > _vm.TRUNCATE_SIZE)?_c('button',{staticClass:"bottom-row load-more-btn",on:{"click":function($event){_vm.truncate = false}}},[_vm._v(" Show more rows ↓ ")]):_c('div',{staticClass:"bottom-row no-postcodes-note"},[_vm._v(" "+_vm._s(_vm.councilMode ? "Councils" : "Postcodes")+" with no vaccination data as well as 0 cases since Jan 2020 are not shown. ")])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_vm._v("Today"),_c('span',{staticStyle:{"font-weight":"normal"}},[_vm._v("*")])])}]

export { render, staticRenderFns }